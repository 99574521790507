import { AttributeEnum } from '../../schema-types'
import { ChipLabelEnumColorMapItem } from '../types'
import { Resources } from '../../types'

type VendorStatus = AttributeEnum<typeof Resources.VENDORS, 'status'>

export const VENDOR_STATUS_COLORS: Record<
  VendorStatus,
  ChipLabelEnumColorMapItem
> = {
  ACTIVE: { background: 'success', text: 'bg-grey1' },
  ARCHIVED: { background: 'error', text: 'bg-grey1' },
}
