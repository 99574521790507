<template>
  <div class="assign-vendors fill-height">
    <v-toolbar
      class="toolbar3"
      dense
      flat
      :height="toolbarHeight"
      short
      :style="{ borderBottom: '1px solid #E1E4E6 !important' }"
    >
      <v-toolbar-title v-text="$t(pageTitle)" />
      <v-spacer />
      <AddVendorsButton :client-id="clientId" />
      <PrioritizeVendorsButton class="ml-2" :client-id="clientId" />
    </v-toolbar>

    <AssignVendorsWidget :client-id="clientId" :height="widgetsHeight" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import AssignVendorsWidget from './AssignVendorsWidget.vue'
import AddVendorsButton from './AddVendorsButton.vue'
import PrioritizeVendorsButton from './PrioritizeVendorsButton.vue'

import { VueConstructor } from 'vue/types/umd'

type VueWithInjections = VueConstructor<Vue>

export default (Vue as VueWithInjections).extend({
  name: 'AssignVendors',
  components: {
    AddVendorsButton,
    AssignVendorsWidget,
    PrioritizeVendorsButton,
  },
  props: {
    clientId: { type: Number, required: true },
    height: { type: Number, default: 800 },
    pageTitle: { type: String, required: true },
  },
  computed: {
    toolbarHeight(): number {
      return 80
    },
    widgetsHeight(): number {
      return this.height - this.toolbarHeight
    },
  },
})
</script>
