<template>
  <ResourceAllowedOperations
    v-slot="{ allowsActions }"
    :resource-name="resourceName"
  >
    <EntityActions
      v-if="allowsActions"
      v-slot="{ actions }"
      :entity-id="clientId"
      :resource-name="resourceName"
    >
      <v-btn
        v-if="hasAction(actions, actionName)"
        class="text-none"
        color="ttPrimary"
        dark
        depressed
        :fab="$vuetify.breakpoint.smAndDown"
        :small="$vuetify.breakpoint.smAndDown"
        @click="openModal"
      >
        <v-icon>mdi-priority-high</v-icon>
        <span
          v-if="$vuetify.breakpoint.mdAndUp"
          class="ml-1"
          v-text="$t('vendors.tabs.assign-vendors.prioritize-vendors-btn')"
        />
      </v-btn>
    </EntityActions>
  </ResourceAllowedOperations>
</template>

<script lang="ts">
import Vue from 'vue'

import { Resources } from '@/tt-entity-design/src/types'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { ClientActions } from '@/tt-entity-design/src/components/clients/types'
import { Action } from '@/tt-widget-factory/services/resource-action/types'

export default Vue.extend({
  name: 'PrioritizeVendorsButton',
  props: {
    clientId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    actionName(): string {
      return ClientActions.PrioritizeVendors
    },
    resourceName(): string {
      return Resources.CLIENTS
    },
  },
  methods: {
    hasAction(actions: Action[], actionName: string): boolean {
      return actions.some((action) => action.actionName === actionName)
    },
    async openModal() {
      this.$eventManager.dispatchEvent(EntityIntentTypes.RUN_ACTION, {
        title: 'vendors.tabs.assign-vendors.prioritize-vendors.title',
        actionName: ClientActions.PrioritizeVendors,
        resourceName: Resources.CLIENTS,
        entityId: this.clientId,
      })
    },
  },
})
</script>
